// import { useMemo } from 'react';
import { ThemeProvider, CssBaseline, GlobalStyles } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import svLocale from 'date-fns/locale/sv';

import { useSwitchableTheme, DarkContext } from './state/useSwitchableTheme';
import { useChannelDataset, DatasetContext } from './state/dataset';
import { GraphPage } from './ui/GraphPage';
import { getThemeConfig } from './appConfig';

export const App = () => {
  const { theme, darkCtx } = useSwitchableTheme(getThemeConfig);
  const records = useChannelDataset();

  return (
    <DarkContext.Provider value={darkCtx}>
      <DatasetContext.Provider value={records}>
        <GlobalStyles
          styles={{
            body: { overflow: 'hidden' },
            '::-webkit-scrollbar ': {
              width: 5,
            },
            '::-webkit-scrollbar-track ': {
              background: theme.palette.background.paper,
            },
            '::-webkit-scrollbar-thumb ': {
              background: theme.palette.action.disabled,
              borderRadius: 2.5,
            },
          }}
        />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={svLocale}>
            <GraphPage />
          </LocalizationProvider>
        </ThemeProvider>
      </DatasetContext.Provider>
    </DarkContext.Provider>
  );
};
