import { useEffect, useContext } from 'react';
import { bi } from 'tri-fp';
import { useForm } from 'react-hook-form';
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Toolbar,
  TextField as MuiTextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// import { formatISO } from 'date-fns/fp';

import { RangeExpander } from './RangeExpander';
import { defaultLimit } from '../appConfig';
import { formValuesToDataTarget } from '../state/dataTarget';
import { DatasetContext } from '../state/dataset';

const TextField = styled(MuiTextField)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const ChannelBar = ({ firstfetch, sendParent, channelRid, id, single }) => (
  <Toolbar
    // variant="dense"
    disableGutters
    sx={{ px: 2, justifyContent: 'flex-end', bgcolor: 'background.default' }}
  >
    <Box flexGrow={1}>
      <Typography component="span">{firstfetch ? 'Channel' : channelRid}</Typography>
    </Box>
    <IconButton
      aria-label="delete"
      onClick={() => sendParent({ type: 'REMOVE', id, channelRid })}
      disabled={single}
    >
      <DeleteIcon />
    </IconButton>
  </Toolbar>
);

const ErrorMsg = ({ error }) =>
  Boolean(error) && (
    <Toolbar variant="dense" disableGutters sx={{ px: 2 }}>
      <WarningIcon color="error" sx={{ mr: 1 }} />
      <Typography color="error">{error.message}</Typography>
    </Toolbar>
  );

export const DataFetchForm = ({
  sendParent,
  matches,
  id,
  dataTarget: { since: sinceInit, from: fromInit, to: toInit, channelRid, limit },
  single,
  error,
  send,
}) => {
  const firstfetch = ['start', 'fetching'].some(matches);
  const fetching = ['fetching', 'refetching'].some(matches);
  const records = useContext(DatasetContext);

  const { register, handleSubmit, setFocus, setValue, watch } = useForm({
    defaultValues: {
      since: sinceInit,
      from: fromInit,
      to: toInit,
      channelRid: '',
    },
  });
  const since = watch('since');
  const from = watch('from');
  const to = watch('to');

  useEffect(() => void bi(setFocus)('channelRid'), [setFocus]);

  const onSubmit = (data) => {
    console.log('DataFetchForm.onSubmit:', data);
    send({
      type: 'FETCH',
      params: formValuesToDataTarget(data),
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ChannelBar {...{ firstfetch, sendParent, channelRid, id, single }} />
      <Box display="flex" flexDirection="column" px={2} pt={1}>
        {firstfetch && (
          <FormControl fullWidth size="small" sx={{ my: 1 }}>
            <InputLabel id="demo-simple-select-label">Channel</InputLabel>
            <Select
              labelId="channelRid-label"
              id="channelRid"
              // value={age}
              label="Channel"
              defaultValue=""
              // onChange={handleChange}
              required
              inputProps={register('channelRid')}
            >
              {records.map(({ id: chId, name, rid }) => (
                <MenuItem key={rid} value={rid}>{`${chId} (${rid}${
                  name ? `, ${name}` : ''
                })`}</MenuItem>
              ))}
              {/* <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
        )}
      </Box>
      <RangeExpander {...{ since, from, to, setValue }} />
      <Box display="flex" alignItems="center" pb={1} px={2}>
        <TextField
          id="limit"
          label="Limit"
          type="number"
          defaultValue={limit ?? defaultLimit}
          inputProps={register('limit')}
          sx={{ display: '', flexGrow: 1 }}
        />
        <Box p={1} /* spacer */ />
        {fetching ? (
          <CircularProgress />
        ) : (
          <>
            <IconButton
              aria-label="cancel"
              onClick={() => send('CANCEL')}
              sx={{ display: firstfetch ? 'none' : 'inline-flex' }}
            >
              <CancelIcon />
            </IconButton>
            <IconButton aria-label="fetch" type="submit" disabled={fetching}>
              <CloudDownloadIcon />
            </IconButton>
          </>
        )}
      </Box>
      <ErrorMsg error={error} />
    </form>
  );
};
