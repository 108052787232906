import { useMemo } from 'react';
import * as R from 'ramda';
import { styled } from '@mui/material/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';

import { echarts } from '../utils/echarts';
import { colors } from '../appConfig';

const serieDefaults = () => ({
  // name: 'SerieA',
  type: 'line',
  // smooth: true,
  // symbol: 'none',
  sampling: 'lttb',
  symbolSize: 6,
  // itemStyle: {
  //   color: colors[idx],
  //   // width: 1,
  // },
  // areaStyle: {},
  large: true, // TODO: make dynamic?
  // data: randData,

  // FOR MULTIPLE PLOTS: (handled from caller)
  // xAxisIndex: 1,
  // yAxisIndex: 1,
});

const getTitleConf = (title) => ({
  title: {
    left: 'left',
    text: title,
  },
});

const getBaseOptions = (imgName) => ({
  // Global palette:
  color: colors,
  tooltip: {
    trigger: 'axis',
    // position: (pt) => [pt[0], '10%'],
    axisPointer: {
      type: 'line',
      // animation: false,
      label: {
        show: true,
      },
    },
    // renderMode: 'richText',
    // transitionDuration: 0,
  },
  legend: {
    textStyle: {
      color: '#777',
    },
  },
  title: {},
  toolbox: {
    orient: 'vertical',
    right: 10,
    top: 20,
    feature: {
      dataZoom: {
        yAxisIndex: 'none',
      },
      dataView: { readOnly: true },
      restore: {},
      saveAsImage: {
        name: imgName,
      },
      // saveAsJson: {},
    },
  },
  darkMode: true,
  axisPointer: {
    link: { xAxisIndex: 'all' },
  },
  series: [],
});

const generateOptions = (plotCount, series) => {
  const height = 92 / plotCount - 2;
  const plots = R.range(0, plotCount);
  const imgName = series?.[0]?.data?.[0]?.[0]?.toISOString();
  return {
    ...getBaseOptions(imgName),
    grid: R.map((idx) => ({
      left: 80,
      right: 50,
      height: `${height}%`,
      top: `${3 + (height + 2) * idx}%`,
      // show: true,
      // borderColor: '#777',
    }))(plots),
    xAxis: R.map((gridIndex) => ({
      type: 'time',
      scale: true,
      boundaryGap: false,
      gridIndex,
    }))(plots),
    yAxis: R.map((gridIndex) => ({
      type: 'value',
      scale: true,
      gridIndex,
      splitLine: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      // minorSplitLine: {
      //   show: true,
      //   lineStyle: {
      //     color: '#333',
      //   },
      // },
    }))(plots),
    dataZoom: R.map(() => ({
      show: true,
      realtime: true,
      start: 0,
      end: 100,
      xAxisIndex: plots,
    }))(plots),
  };
};

const ReactEChart = styled(ReactEChartsCore)({
  flexGrow: 1,
});

export const MultiGraph = ({ series: seriesData, title, plotCount }) => {
  const series = useMemo(
    () => R.addIndex(R.map)((serie, idx) => ({ ...serieDefaults(idx), ...serie }))(seriesData),
    [seriesData],
  );
  const option = useMemo(
    () => ({
      ...generateOptions(plotCount, series),
      series,
      ...(title ? getTitleConf(title) : {}),
    }),
    [plotCount, title, series],
  );
  return (
    <ReactEChart
      echarts={echarts}
      option={option}
      notMerge
      // lazyUpdate
      theme="dark"
      // mask="__-__-____ __:__"
      // onChartReady={Function.prototype}
      // onEvents={EventsDict}
      // opts={option}
    />
  );
};
