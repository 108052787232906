import * as R from 'ramda';
import { random, delay } from 'rambdax';
import { createClient } from 'faybl-client/browser'; // eslint-disable-line

import { replies } from '../utils/mock';
import { testEndpoint, mode } from '../appConfig';

const mockMode = mode === 'mock';

export const getHost = () => window.location.host;
export const getProtocol = () => window.location.protocol;
export const isLocalhost = () => getHost().startsWith('localhost');

export const endpoint = mode === 'prod' ? getHost().replace(/data-explorer\./, '') : testEndpoint;
const testUrl = `https://data-history.${testEndpoint}/read`;

export const stringifyParams =
  ({ pairSeparator = '=', itemsSeparator = '&' } = {}) =>
  (params) =>
    `${Object.entries(params)
      .map((pair) => pair.join(pairSeparator))
      .join(itemsSeparator)}`;

export const urlParams = stringifyParams();

export const appUrl = (app = '', relativePath = '', params = '') =>
  `${getProtocol()}//${getHost().replace(/[^.]+/, app)}${relativePath}${
    params ? `?${urlParams(params)}` : ''
  }`;

export const mocked = (mock) => (fn) => mockMode ? (R.is(Function)(mock) ? mock() : mock) : fn();

export const mockedAsync = async (mock, fnP) =>
  !mockMode ? fnP() : delay(1000).then(R.is(Function)(mock) ? mock : () => mock);

// let uglyIdForLogging = 0; // eslint-disable-line

export const fetchCheckedJson = async (url, { refreshTokenFunc, ...options } = {}) => {
  // const msgId = uglyIdForLogging;
  // uglyIdForLogging += 1; // eslint-disable-line
  // console.log(`Will fetch [${msgId}]:`, url);
  const res = await fetch(url, options);
  const { ok, status, statusText } = res;
  // console.log(`Response status [${msgId}]:`, status, ok ? 'ok' : 'not ok');
  const json = res.json ? await res.json() : {};
  if (!ok) {
    if (status === 401 && refreshTokenFunc) {
      //  && json.error === TOKEN_IS_EXPIRED
      return refreshTokenFunc().then(() => fetchCheckedJson(url, options));
    }
    const error = Error(`Fetch failed: ${status} ${statusText}`);
    error.response = { status, statusText };
    return Promise.reject(error);
  }
  // console.log(`JSON data [${msgId}]:`, JSON.stringify(json, null, 2));
  return json;
};

// Faybl communication
// ===================

export const faybl = createClient({
  tenantUrl: endpoint,
  options: {
    keepAliveInterval: 3000,
    reconnect: true,
  },
});
console.log('Fayble client created:', typeof faybl, faybl);

// Data-history communication
// ==========================

const getParams = ({ channelRid, from, to, limit, ascending }) => ({
  rid: channelRid,
  projection: ['ts', 'pl'],
  from,
  to,
  limit,
  ascending,
  // sampleInterval: 60, // from range, but for dev none or input
});

const getAscending = ({ from }) => Boolean(from);

export const fetchData = (params = {}) => {
  const { channelRid } = params;
  if (!channelRid) return undefined;
  const ascending = getAscending(params);
  const body = getParams({ ...params, ascending });
  console.log({ body });
  const mockFn = () => {
    const ix = R.mathMod(parseInt(channelRid, 36), replies.length + 1);
    const noFail = Boolean(random(0, 4));
    const res = noFail ? replies[ix] : Promise.reject(Error('Failed to fetch'));
    console.log({ res });
    return res;
  };
  const liveFn = () =>
    fetchCheckedJson(!mockMode && isLocalhost() ? testUrl : appUrl('data-history', '/read'), {
      method: 'POST',
      // mode: 'no-cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    }).then(R.when(R.propEq('count', 0), () => Promise.reject(Error('Empty data returned'))));
  return mockedAsync(mockFn, liveFn).then(R.evolve({ data: R.when(() => !ascending, R.reverse) }));
};
