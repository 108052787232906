import * as R from 'ramda';
import { bi } from 'tri-fp';

/* eslint-disable no-null/no-null */
export const OBJ = Object.freeze(Object.create(null));
/* eslint-enable no-null/no-null */
export const ARR = Object.freeze([]);
export const FUNC = Function.prototype;

const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
const getChar = () => charset.charAt(Math.floor(Math.random() * charset.length));
export const generateString = (length = 14) =>
  R.pipe(R.range(0), R.reduce((x) => `${x}${getChar()}`)(''))(length);

// isNonPrimitive :: a -> Boolean
export const isNonPrimitive = (x) => R.or(Array.isArray(x), R.is(Object, x));

// isPrimitive :: a -> Boolean
export const isPrimitive = R.complement(isNonPrimitive);

export const maybeUnjson = (x) => {
  const [error, parsed] = bi(JSON.parse)(x);
  return error ? x : parsed;
};

export const createStore = (initState = {}, reducer = R.identity) => {
  /* eslint-disable fp/no-mutation, fp/no-let, functional/no-let */
  let state = initState;
  return {
    getState: (key) => (key ? state[key] : state),
    dispatch: (action) => (state = reducer(state, action)),
  };
  /* eslint-enable fp/no-mutation, fp/no-let, functional/no-let */
};

export const adjustCollectionItem =
  (key = 'id') =>
  (id, adjuster) =>
  (items) => {
    const idx = R.findIndex(R.propEq(key, id))(items);
    if (idx === -1) return items;
    return R.adjust(idx, adjuster)(items);
  };
