export const isLocalhost = () => window.location.host.startsWith('localhost');

// export const inputWidth = 220;
export const drawerBodyWidth = 380;
export const defaultLimit = 1000;

// export const testEndpoint = 'core.faybl.online';
export const testEndpoint = 'packsize.atosis.online';
export const localMode = 'mock'; // test, mock
export const mode = isLocalhost() ? localMode : 'prod'; // mock, test, prod

export const getThemeConfig = () => ({
  palette: {
    primary: { main: '#8bc34a' },
    background: { paper: '#201C3A', default: '#100C2A' },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    // MuiIconButton: {
    //   styleOverrides: {
    //     root: {
    //       color: 'inherit',
    //     },
    //   },
    // },
  },
});

export const colors = [
  'rgb(50, 210, 10)',
  'rgb(150,83,197)',
  'rgb(255,150,0)',
  'rgb(220,80,20)',
  '#32C5E9',
  '#FFDB5C',
  '#fb7293',
  '#9d96f5',
  '#c23531',
  '#dd6b66',
  '#9FE6B8',
  '#ca8622',
  '#e7bcf3',
  '#61a0a8',
  '#37A2DA',
  '#8378EA',
  '#d48265',
  '#E062AE',
  '#67E0E3',
  '#ff9f7f',
  '#c4ccd3',
  '#e69d87',
  '#8dc1a9',
  '#ea7e53',
  '#eedd78',
  '#73a373',
  '#73b9bc',
  '#91c7ae',
  '#2f4554',
  '#74af83',
  '#E690D1',
  '#bda29a',
  '#6e7074',
  '#546570',
  '#7289ab',
  '#91ca8c',
  '#f49f42',
  '#96BFFF',
];
