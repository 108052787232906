import * as R from 'ramda';
import {
  IconButton,
  Chip,
  List,
  MenuItem,
  Toolbar,
  TextField as MuiTextField,
  FormControl,
  Box,
  // FormHelperText,
} from '@mui/material';
// import { useFormControl } from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useForm } from 'react-hook-form';

import { PathItem } from './PathItem';
import { colors } from '../appConfig';

const Form = styled('form')({ width: '100%' });

const PathList = styled(List)(() => ({
  maxHeight: 240,
  overflowY: 'auto',
}));

const TextField = styled(MuiTextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    padding: theme.spacing(1, 1, 0.5, 1),
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
  },
}));

const createPlotOptions = R.pipe(
  R.add(1),
  R.range(0),
  R.map((x) => (
    <MenuItem key={x} value={x}>
      {x}
    </MenuItem>
  )),
);

// const SubmitButton = ({ watch }) => {
//   const val = watch('alias');
//   const { focused } = useFormControl() || {};
//   console.log(focused);
//
//   return (
//     <IconButton sx={{ display: focused ? '' : 'none' }} type="submit" aria-label="submit">
//       <DeleteIcon />
//       {val}
//       {focused}
//     </IconButton>
//   );
// };

export const DataControl = ({
  id,
  sendParent,
  series,
  send,
  single,
  count,
  plotCount,
  alias,
  inGraphState,
  graphedSeries,
}) => {
  // console.log('channelMachine.value:', value, from, to);

  const plotOptions = createPlotOptions(plotCount);

  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    sendParent({ type: 'ALIAS', id, ...data });
    return false;
  };

  return (
    <>
      <Toolbar
        disableGutters
        sx={{ pl: 1, pr: 2, justifyContent: 'flex-end', bgcolor: 'background.default' }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            {Boolean(series.length) && (
              <Box display="flex">
                <TextField
                  id="alias"
                  fullWidth
                  defaultValue={alias}
                  inputProps={register('alias')}
                  // aria-describedby="my-helper-text"
                />
              </Box>
              // <SubmitButton {...{ watch }} />
              // <FormHelperText id="my-helper-text">ENTER to confirm</FormHelperText>
            )}
            {}
          </FormControl>
        </Form>
        <Chip variant="outlined" size="small" label={count} />
        <IconButton
          aria-label="edit"
          onClick={() => send({ type: 'EDIT', active: false })}
          disabled={!inGraphState}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => sendParent({ type: 'REMOVE', id })}
          disabled={single}
        >
          <DeleteIcon />
        </IconButton>
      </Toolbar>
      <PathList dense>
        {series.map((serie, idx) => {
          const { plot, path, alias: sAlias } = serie;
          const colIdx = R.findIndex(R.propEq('searchRef', `${id}:${path}`))(graphedSeries);
          return (
            <PathItem
              key={path}
              plot={plot}
              path={path}
              alias={sAlias}
              id={id}
              sendParent={sendParent}
              plotOptions={plotOptions}
              idx={idx}
              color={colors[colIdx]}
            />
          );
        })}
      </PathList>
    </>
  );
};
