import { createContext } from 'react';
import { useMachine } from '@xstate/react';
import { createDatasetMachine } from 'faybl-client';

import { faybl } from './api';

export const DatasetContext = createContext();

const spec = {
  entity: 'channel',
  // recordFilter: R.propEq('type', 'machine'),
  // filter: ['=', ['$prop', 'type'], 'machine'],
  projection: ['id', 'name', 'type'],
};

export const useChannelDataset = () => {
  const [
    {
      context: { records },
    },
    // sendDataset,
  ] = useMachine(createDatasetMachine(faybl, spec));

  return records;
};
