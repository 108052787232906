// import * as R from 'ramda';
import {
  Divider,
  Select,
  ListItem,
  TextField as MuiTextField,
  // FormControl,
  // FormHelperText,
} from '@mui/material';
// import { useFormControl } from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';

const Form = styled('form')({ width: '100%' });
const HiddenButton = styled('button')({ display: 'none' });

const SelectDense = styled(Select)(({ theme }) => ({
  padding: theme.spacing(0),
}));

const TextField = styled(MuiTextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    padding: theme.spacing(1, 1, 0.5, 1),
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
  },
}));

const NarrowingListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0.5, 2, 0.5, 1),
  '& .MuiSelect-select': {
    padding: theme.spacing(1, 0, 0.5, 1.5),
  },
}));

export const PathItem = ({ path, alias, plot, id, sendParent, plotOptions, idx, color }) => {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    sendParent({ type: 'UPDATE_SERIE', id, path, ...data });
    return false;
  };

  const plotSelect = (
    <SelectDense
      value={plot}
      onChange={(ev) => sendParent({ type: 'UPDATE_SERIE', id, path, plot: ev.target.value })}
      inputProps={{ autoFocus: idx === 0 }}
    >
      {plotOptions}
    </SelectDense>
  );

  return (
    <>
      <NarrowingListItem
        disablePadding
        sx={color ? { borderRight: `4px solid ${color}`, pr: 1.5 } : {}}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            id="alias"
            defaultValue={alias || path || 'Root data'}
            fullWidth
            inputProps={register('alias')}
          />
          <HiddenButton type="submit">Submit</HiddenButton>
        </Form>
        {plotSelect}
      </NarrowingListItem>
      <Divider />
    </>
  );
};
