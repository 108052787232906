import { createStore } from '../utils/dataUtils';

const reducer = (state, { type, ...rest }) => {
  const reduceActions = {
    init: ({ ctx = {} }) => ({ ...state, ...ctx }),
  };
  const result = reduceActions[type](rest);
  return result;
};

export const { getState, dispatch } = createStore({ channels: [] }, reducer);
