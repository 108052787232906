// import * as R from 'ramda';
import {
  Divider,
  Collapse,
  // FormControl,
  // FormHelperText,
} from '@mui/material';
import { useActor } from '@xstate/react';

import { DataFetchForm } from './DataFetchForm';
import { DataControl } from './DataControl';

export const DataForm = ({
  id,
  service,
  sendParent,
  single,
  plotCount,
  series,
  alias,
  graphedSeries,
}) => {
  const [
    {
      matches,
      context: { error, count, dataTarget },
      // value,
    },
    send,
  ] = useActor(service);
  // console.log('channelMachine.value:', value, from, to);

  const inGraphState = matches('done');

  return (
    <>
      <Collapse in={!inGraphState}>
        <DataFetchForm
          {...{
            sendParent,
            dataTarget,
            single,
            id,
            error,
            matches,
            send,
          }}
        />
      </Collapse>
      <Collapse in={inGraphState}>
        <DataControl
          {...{
            id,
            send,
            sendParent,
            series,
            count,
            alias,
            plotCount,
            single,
            inGraphState,
            graphedSeries,
          }}
        />
      </Collapse>
      <Divider />
    </>
  );
};
