export const getHost = () => window.location.host;
export const getProtocol = () => window.location.protocol;
export const isLocalhost = () => getHost().startsWith('localhost');

export const urlParams = (obj) =>
  `?${Object.entries(obj)
    .map((pair) => pair.join('='))
    .join('&')}`;

export const fromParams = (key) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
};

export const appUrl = (relativePath, params) =>
  `${getProtocol()}//${getHost()}${relativePath}${params ? urlParams(params) : ''}`;

export const jsToUri = (obj) => window.btoa(JSON.stringify(obj));

export const uriToJs = (str) => JSON.parse(window.atob(str));
