import { createMachine, assign, sendParent } from 'xstate';
import * as R from 'ramda';
import { path as pathRx } from 'rambdax';
import { flattenObject } from 'flatten-anything';
import isPlainObject from 'lodash.isplainobject';

import { fetchData } from './api';
import { maybeUnjson, OBJ, isPrimitive } from '../utils/dataUtils';
import { createDataTarget, prepareDataTarget } from './dataTarget';

const dataToSerie = (path) =>
  R.map(({ ts, pl } = {}) => {
    return [new Date(ts), path !== '' ? pathRx(path)(pl ?? {}) : pl];
  });

const resultEventToPreparedSeries = ({ channelRid, points }) =>
  R.pipe(
    // points is ^V
    pathRx('data.data.0.pl'),
    maybeUnjson,
    R.ifElse(
      Array.isArray,
      R.pipe(R.length, R.range(0), R.map(String)),
      R.when(isPlainObject, R.pipe(flattenObject, R.keys)),
    ),
    // flattenObject, // TODO: NEED TO HANDLE JSON PARSED ARRAY ALSO. DONE? TEST!
    // R.keys,
    R.when(R.either(R.isEmpty, isPrimitive), () => ['']),
    R.map((path) => ({
      channelRid,
      path,
      serie: dataToSerie(path)(points),
    })),
  );

export const channelMachineContext = {
  // id: '',
  dataTarget: createDataTarget(),
  error: undefined,
  count: 0,
};

const channelMachineConf = {
  id: 'channel',
  initial: 'init',
  context: channelMachineContext,
  states: {
    init: {
      always: [{ cond: 'isPreset', target: 'refetching' }, { target: 'start' }],
    },
    start: {
      on: {
        FETCH: { target: 'fetching' },
        REFETCH: [{ cond: 'isPreset', target: 'fetching' }, { target: 'start' }],
      },
    },
    refetch: {
      on: {
        FETCH: { actions: ['saveParams'], target: 'refetching' },
        REFETCH: [{ cond: 'isPreset', target: 'fetching' }, { target: 'refetch' }],
        CANCEL: { actions: ['sendActivate'], target: 'done' },
      },
    },
    fetching: {
      entry: ['clearError', 'saveParams'],
      invoke: {
        src: 'fetcher',
        onDone: {
          actions: ['saveCount', 'sendSeries'],
          target: 'done',
        },
        onError: { actions: 'setError', target: 'start' },
      },
    },
    refetching: {
      entry: ['clearError'],
      invoke: {
        src: 'fetcher',
        onDone: {
          actions: ['saveCount', 'sendSeries'],
          target: 'done',
        },
        onError: { actions: 'setError', target: 'refetch' },
      },
    },
    done: {
      on: {
        EDIT: { actions: 'sendActivate', target: 'refetch' },
        // REFETCH: [{ cond: 'isPreset', target: 'fetching' }, { target: 'refetch' }],
        REFETCH: 'refetching',
      },
    },
  },
};

export const channelMachine = createMachine(channelMachineConf, {
  services: {
    fetcher: ({ dataTarget }) => fetchData(prepareDataTarget(dataTarget)),
  },
  guards: {
    isPreset: ({ dataTarget: { channelRid } }) => channelRid,
  },
  actions: {
    sendActivate: sendParent(({ id }, { active = true }) => ({
      type: 'ACTIVATE_CHANNEL',
      id,
      active,
    })),
    saveParams: assign({
      dataTarget: ({ dataTarget: prev }, { params = OBJ }) => R.mergeLeft(params, prev),
    }),
    saveCount: assign({
      count: (ctx, { data: { count } }) => count,
    }),
    sendSeries: sendParent(({ dataTarget, id }, ev) => {
      const {
        data: { data: points },
      } = ev;
      return {
        type: 'SET_SERIES',
        dataTarget,
        series: resultEventToPreparedSeries({ ...dataTarget, points })(ev),
        id,
      };
    }),
    setError: assign({ error: (ctx, { data }) => data }),
    clearError: assign({ error: () => undefined }),
  },
});
