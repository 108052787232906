import { createContext, useMemo } from 'react';
import { createTheme } from '@mui/material';
import createPersistedState from 'use-persisted-state-v2';

const useStoredDark = createPersistedState('darkmode');
export const DarkContext = createContext();

export const useSwitchableTheme = (getThemeConfig) => {
  const [isDark, setDark] = useStoredDark(true);

  const theme = useMemo(() => {
    const themeConfig = getThemeConfig(isDark);
    const themeConf = {
      ...themeConfig,
      palette: {
        ...themeConfig.palette,
        mode: isDark ? 'dark' : 'light',
      },
    };
    const newTheme = createTheme(themeConf);
    window.THEME = newTheme; // eslint-disable-line functional/immutable-data
    return newTheme;
  }, [isDark, getThemeConfig]);

  return useMemo(() => ({ theme, darkCtx: { isDark, setDark } }), [theme, isDark, setDark]);
};
