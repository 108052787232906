import * as ECHARTS from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  CalendarComponent,
  ToolboxComponent,
  TooltipComponent,
  AxisPointerComponent,
  BrushComponent,
  TitleComponent,
  TimelineComponent,
  LegendComponent,
  DataZoomComponent,
  VisualMapComponent,
  TransformComponent,
  DatasetComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

ECHARTS.use([
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  CanvasRenderer,
  CalendarComponent,
  ToolboxComponent,
  TooltipComponent,
  AxisPointerComponent,
  BrushComponent,
  TitleComponent,
  TimelineComponent,
  LegendComponent,
  DataZoomComponent,
  VisualMapComponent,
  TransformComponent,
  DatasetComponent,
]);

export const echarts = ECHARTS;
